<template>
	<div class="programm">

		<LandingpageHeader activeTab="false"></LandingpageHeader>

		<!-- HEADER -->

		<div class="landingpage__header">

			<div class="landingpage__header__text">
				<h1>SportlerPlus<br>presents <span>"Teams"</span></h1>

				<p><b>Join the club!</b> Kommt ins SportlerPlus Team-Programm und sichert euch spannende Vorteile. Bringt euer Training gemeinsam mit uns auf das nächste Level. Mit unserer Fitness-App und den SportlerPlus Supplements warten spannende Vorteile auf euch!</p>

				<div class="button button--primary" @click="openContactModal">Jetzt Vorteile sichern</div>
			</div>

			<div class="landingpage__header__image">
				<img src="@/assets/landingpage/teams-header.jpg" srcset="@/assets/landingpage/teams-header@2x.jpg 2x" alt="Teams SportlerPlus">
			</div>

		</div>

		<div class="programm__features">

			<div class="programm__features__wrapper">
			
				<div class="programm__features__headline">
					Deine Vorteile:
				</div>

				<div class="programm__features__item">
					<img src="@/assets/ic_premium_check.svg" class="programm__features__item__icon" alt="">
					<div class="programm__features__item__text">
						<div class="programm__features__item__title">
							20x SportlerPlus Premium
						</div>
						<div class="programm__features__item__meta">
							im Wert von 1.000€ (UVP)
						</div>
					</div>
				</div>

				<div class="programm__features__item">
					<img src="@/assets/ic_premium_check.svg" class="programm__features__item__icon" alt="">
					<div class="programm__features__item__text">
						<div class="programm__features__item__title">
							50€ Shop-Gutschein*
						</div>
						<div class="programm__features__item__meta">
							für den <a href="https://shop.sportlerplus.de" target="_blank">SportlerPlus-Shop</a>
						</div>
					</div>
				</div>

				<div class="programm__features__item">
					<img src="@/assets/ic_premium_check.svg" class="programm__features__item__icon" alt="">
					<div class="programm__features__item__text">
						<div class="programm__features__item__title">
							20% Team-Rabatt
						</div>
						<div class="programm__features__item__meta">
							für den <a href="https://shop.sportlerplus.de" target="_blank">SportlerPlus-Shop</a>
						</div>
					</div>
				</div>

			</div>

		</div>

		<div class="programm__promo">
			<div class="programm__promo__content">
				<div class="programm__promo__text">
					<div class="programm__promo__badge">
						Vorteile sichern
					</div>
					<h2>Wir bringen euer Training auf das nächste Level!</h2>
					<p>Gemeinsam machen wir euch fit für die Saison! Werdet mit eurem Team Teil der SportlerPlus Community und sichert euch die exklusiven Vorteile. Schreibt uns bei Interesse direkt an und wir melden uns bei euch.</p>
					<div class="button button--primary button--small" @click="openContactModal">Vorteile sichern</div>
				</div>
				<div class="programm__promo__image">
					<img src="@/assets/landingpage/teams-football.jpg" srcset="@/assets/landingpage/teams-football@2x.jpg 2x" alt="SportlerPlus Teams">
				</div>
			</div>
		</div>

		<LandingpageFooter></LandingpageFooter>

		<ContactModal :programmeType="'teamProgramme'"></ContactModal>

		<div class="hinttext">
			* Der Mindestbestellwert beträgt 95€
		</div>

	</div>
</template>

<script>
import LandingpageHeader from "@/components/LandingpageHeader.vue";
import LandingpageFooter from "@/components/LandingpageFooter.vue";
import ContactModal from "@/components/ContactModal.vue";

export default {
	name: 'Teams',
	metaInfo() {
		return { 
			title: "SportlerPlus  - Join the Club - Spannende Vorteile für dein Team!",
			meta: [
				{
					vmid: "og:title",
					property: "og:title",
					content: "SportlerPlus  - Join the Club - Spannende Vorteile für dein Team!"
				},
				{
					vmid: "og:site_name",
					property: "og:site_name",
					content: "SportlerPlus"
				},
				{
					vmid: "og:type",
					property: "og:type",
					content: "website"
				},
				{
					vmid: "og:image",
					property: "og:image", 
					content: "/img/Team.jpg"
				}
			]
		}
	},
	components: { LandingpageHeader, LandingpageFooter, ContactModal },
	methods: { 
		scrollToTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				/* TODO: Smooth Transition doesn't work */
				behavior: 'smooth'
			});
		},
		openContactModal() {
			this.$modal.show("contactModal");
		},
	}
}
</script>

<style lang="scss">

	.landingpage__header__text {

		padding: 96px 16px 51px;

		@media screen and (min-width: 768px) {
			padding: 104px 24px 127px;
			min-height: calc(100vh - 125px - 136px);
		}
	}

	.programm {
		position: relative;
		background: $colorWhite;
		overflow: hidden;

		nav {
			z-index: 2;

			@media screen and (min-width: 768px) {
				background: none;
				position: relative;
			}
		}

		footer {
			box-shadow: 0 -1px 0 0 rgba($colorBlack, .08);
		}

		&__features {
			position: relative;
			z-index: 2;
			max-width: 1088px;
			margin: 0px auto;
			padding: 0 16px;

			@media screen and (min-width: 768px) {
				padding: 0 24px;
			}

			&__wrapper {
				display: flex;
				flex-direction: column;
				padding: 17px 0;

				@media screen and (min-width: 768px) {
					box-shadow: inset 0 1px 0 0 rgba($colorBlack, .08);
					flex-direction: row;
				}
			}

			&__headline {
				font-size: 14px;
				font-weight: 600;
				color: rgba($colorBlack, .48);
				text-transform: uppercase;
				margin-bottom: 16px;
				letter-spacing: -0.2px;

				@media screen and (min-width: 768px) {
					display: none;
				}
			}

			&__item {
				display: flex;
				padding: 28px 0;
				box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .08);

				&:last-child {
					box-shadow: none;
				}

				@media screen and (min-width: 768px) {
					width: calc((100% - 48px) / 3);
					box-shadow: none;
				}

				&__icon {
					width: 28px;
					margin-right: 16px;
				}

				&__text {
					display: flex;
					flex-direction: column;
					justify-content: center;
				}

				&__title {
					font-size: 17px;
					line-height: 24px;
					color: $colorBlack;
					font-weight: 600;
					margin-top: -2px;
					margin-bottom: -3px;
				}

				&__meta {
					font-size: 11px;
					font-weight: 500;
					color: rgba($colorBlack, .64);

					a {
						color: rgba($colorBlack, .64);
					}
				}
			}
		}

		&__promo {
			border-top: 1px solid rgba($colorBlack, .08);
			padding: 32px 0;
			background: $colorBackground;

			@media screen and (min-width: 768px) {
				padding: 160px 0 192px;
			}

			&__content {
				display: flex;
				flex-direction: column-reverse;
				position: relative;
				max-width: 1088px;
				margin: 0px auto;
				padding: 32px 16px 96px;

				@media screen and (min-width: 768px) {
					flex-direction: row;
					align-items: center;
					padding: 0 24px;
				}
			}

			&__image {
				width: 100%;
				margin-bottom: 25px;

				img {
					width: 100%;
					border-radius: 8px;
				}

				@media screen and (min-width: 768px) {
					display: flex;
					width: 40%;
					justify-content: flex-start;
					margin: 0 80px 0 80px;
					padding-left: 80px;

					img {
						width: auto;
						border-radius: 0;
					}
				}
			}

			&__text {
				width: 100%;

				h2 {
					font-size: 32px;
					margin: 18px 0 16px;

					@media screen and (min-width: 768px) {
						margin: 18px 0 25px;
					}
				}

				.button {
					display: inline-flex;
					margin-top: 17px;
					justify-content: center;

					@media screen and (min-width: 768px) {
						width: auto;
						padding-left: 32px !important;
						padding-right: 32px !important;
					}
				}

				p {
					@media screen and (max-width: 767px) {
						margin-bottom: 8px;
					}
				}

				@media screen and (min-width: 768px) {
					width: 60%;
				}
			}

			&__badge {
				display: inline-flex;
				border-radius: 2px;
				background: rgba($colorPrimary, .12);
				color: $colorPrimary;
				padding: 5px 9px;
				font-size: 14px;
				font-weight: 700;
				letter-spacing: -0.29px;
			}
		}

		.hinttext {
			display: flex;
			justify-content: center;
			font-size: 14px;
			font-weight: 600;
			color: rgba($colorBlack, .48);
			padding: 19px 0 18px;
			box-shadow: inset 0 1px 0 0 rgba($colorBlack, .08);
		}
	}

	/* Display text inputs left aligned */
	.programm{
		input {
			text-align: left !important;
		}
	}
</style>